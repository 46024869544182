import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planWegeFish = {
  title: "Bez Laktozy",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Bananowe racuchy z musem truskawkoym",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Sałatka z jajkiem",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Sakiewki drobiowe z warzywami",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Śliwki pieczone pod jaglaną kruszonką",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description: "Burgery wielowarzywne",
    },
  ],
}

const PlanWithoutLactosePage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <Seo title="Diata BEZ LAKTOZY" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout diet={planWegeFish} image={data.file}>
        Dieta skierowana dla osób zmagających się ze stwierdzoną nietolerancją
        laktozy lub dla tych, którzy czują się źle po produktach zawierających
        laktozę i podejrzewają u siebie słabą jej tolerancję. Jadłospis jest
        skomponowany w taki sposób, by dostarczać wszystkich potrzebnych witamin
        i minerałów, a jednocześnie jest smaczny i urozmaicony.
        <br />
        *Można ją połączyć z dietą bez glutenu.
      </DietLayout>
    </Layout>
  )
}

export default PlanWithoutLactosePage
